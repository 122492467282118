import React from "react";

const Footer: React.FC = () => (
  <footer className="bg-bluegray-200 opacity-90 mt-12">
    <div className="pt-16 pb-6 px-4">
      <h1 className="text-lg">
        © {new Date().getFullYear()}{" "}
        <a href="https://pauldlug.com">Paul Dlug</a>
      </h1>
    </div>
  </footer>
);

export default Footer;
