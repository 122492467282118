import { Link } from "gatsby";
import React from "react";

const Header: React.FC<{ readonly siteTitle: string }> = ({ siteTitle }) => (
  <header className="bg-bluegray-900 mb-6">
    <div className="max-w-lg py-6 px-4">
      <h1 className="text-2xl font-bold">
        <Link to="/" className="text-white">
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
);

export default Header;
